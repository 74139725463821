<template>
  <div class="help-and-support">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="welcome">
            <h1>Contact Us</h1>
          </div>
          <base-card>
            <v-card-text>
              <v-form ref="form" @submit.prevent="submitForm">
                <v-textarea
                  :disabled="isSendingMessage"
                  v-model="contactForm.message"
                  label="Message"
                  outlined
                ></v-textarea>
                <v-btn
                  :loading="isSendingMessage"
                  dark
                  @click.prevent="submitForm"
                  >Send Message
                </v-btn>
              </v-form>
            </v-card-text>
          </base-card>
        </v-col>
        <!-- <v-col cols="6">
          <div class="welcome">
            <h1>Frequently Asked Questions</h1>
          </div>
          <v-expansion-panels class="border-0" flat>
            <v-expansion-panel
              v-for="(item, i) in 3"
              :key="i"
              class="mb-4 py-3 border-0"
              flat
            >
              <v-expansion-panel-header class="font-weight-bold"
                >Frequently Asked Question #{{ item }}</v-expansion-panel-header
              >
              <v-expansion-panel-content
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo
                consequat.</v-expansion-panel-content
              >
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSendingMessage: false,
      contactForm: {
        message: "",
      },
    };
  },

  methods: {
    submitForm() {
      let vm = this;
      vm.isSendingMessage = true;
      window.setTimeout(function () {
        vm.isSendingMessage = false;
        vm.contactForm.message = "";
        vm.$toast.success("Message sent successfully");
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.help-and-support {
  padding: 12px 20px;

  .welcome {
    margin-bottom: 30px;
  }
}
</style>